<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getContractRegistry.name"
                    :avatarData="getContractRegistry.currentMenager"
                    @clearSearch="clearTable"
                />

                <Table
                    :title="getContractRegistry.name"
                    :items="getContractTable.items"
                    :headers="headers"
                    :length="getContractTable.pages"
                    :page="getContractTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getContractSearchFields.searchFields"
                    :loading="getContractTable.loading"
                >
                    <Columns slot="columns" :items="getContractTable.items" />
                </Table>
                <Modal
                    :title="$t('contracts:addingContract')"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addContract()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('contracts:addContract') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from '../../../components/Registries/Contracts/Table/Columns'
import AEContent from './../../../components/Registries/Contracts/Modal/Content'
import Buttons from './../../../components/Registries/Contracts/Modal/Buttons'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('contracts:index'),
                    value: 'index',
                    width: '10%',
                    showSearch: true,
                },
                {
                    text: this.$t('contracts:contractor'),
                    value: 'contractor.name',
                    width: 'auto',
                    showSearch: true,
                },
                {
                    text: this.$t('contracts:number'),
                    value: 'number',
                    width: '10%',
                    showSearch: true,
                },
                {
                    text: this.$t('contracts:title'),
                    value: 'title',
                    width: '30%',
                    showSearch: true,
                },
                {
                    text: this.$t('contracts:contractType'),
                    value: 'contractType.field',
                    width: '10%',
                    showSearch: true,
                },
                {
                    text: this.$t('contracts:status'),
                    value: 'status.name',
                    width: '10%',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '8%',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getContractRegistry',
            'getContractTable',
            'getContractSearchFields',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions(['fetchContractsTable', 'fetchUsers']),
        ...mapMutations([
            'clearContractModal',
            'clearContractErrors',
            'setContractTable',
            'clearContractTable',
            'setContractSearch',
        ]),
        add() {
            return this.checkPermissions('CREATE')
        },
        closeModal() {
            this.open = false
        },
        async addContract() {
            this.open = true
            this.clearContractErrors()
            this.clearContractModal()
        },
        setSearchFields(searchFields) {
            this.setContractSearch(searchFields)
        },
        setSearch(search) {
            this.setContractTable({ search, page: 1 })
            this.fetchContractsTable()
        },
        setPage(page) {
            this.setContractTable({ page })
            this.fetchContractsTable()
        },
        setSort(sort) {
            this.setContractTable({ sort })
            this.fetchContractsTable()
        },

        clearTable() {
            this.getSearch.text = ''
            this.clearContractTable()
            this.fetchContractsTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/contracts/:id')
            store.commit('clearContractTable')
        store.dispatch('fetchContractsTable').then(() => next())
    },
}
</script>
